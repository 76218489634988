import { Button, Form, Input, InputNumber, Radio, Select, Switch } from 'antd';
import { CmsApi, CmsHelp, CmsHelpCategory } from 'apis/cms';
import LOCALS from 'commons/locals';
import i18n from 'i18n';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { langOptionList } from './article-list';
import commonApi from 'apis/common';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// @ts-ignore
import ImageUploader from 'quill-image-uploader';
import FormImageUpload from 'components/form-image-upload';

// #2 register module
Quill.register('modules/imageUploader', ImageUploader);

const CmsArticleDetailPage = () => {
  const [searchParams] = useSearchParams();
  const [article, setArticle] = useState<CmsHelp>();
  const [form] = Form.useForm();

  const [helpCategoryList, setHelpCategoryList] = useState<CmsHelpCategory[]>(
    []
  );
  useEffect(() => {
    CmsApi.getHelpCategory().then((res) => {
      setHelpCategoryList(res);
    });
  }, []);

  useEffect(() => {
    const id = searchParams.get('id');
    if (!id) return;

    CmsApi.getHelpDetail(Number(id)).then((res) => {
      setArticle(res);
      form.setFieldsValue(res);
    });
  }, [form, searchParams]);

  return (
    <div className="max-w-4xl flex justify-center mx-auto">
      <Form
        layout="vertical"
        form={form}
        className="w-full"
        onFinish={async (data) => {
          if (article) {
            await CmsApi.helpUpdate({
              id: article.id,
              ...data,
            });

            window.location.reload();
          } else {
            const { id } = await CmsApi.helpCreate({ ...data });
            window.location.href = `/cms/article-detail?id=${id}`;
          }
        }}
      >
        <Form.Item name="categoryId" label="分类">
          <Select
            placeholder={i18n.t(LOCALS.please_select) || ''}
            style={{ minWidth: 120 }}
            options={helpCategoryList.map(({ id: value, name: label }) => {
              return {
                value,
                label,
              };
            })}
          />
        </Form.Item>
        <Form.Item name="path" label="页面路径">
          <Input />
        </Form.Item>
        <Form.Item name="title" label="标题">
          <Input />
        </Form.Item>
        <Form.Item name="icon" label="图片">
          <FormImageUpload />
        </Form.Item>
        <Form.Item name="language" label="语言">
          <Radio.Group>
            {langOptionList.map(({ value, label }) => {
              return (
                <Radio key={value} value={value}>
                  {label}
                </Radio>
              );
            })}
          </Radio.Group>
        </Form.Item>
        <Form.Item name="sort" label="排序">
          <InputNumber className="w-full" />
        </Form.Item>
        <Form.Item
          name="showStatus"
          label="发布状态"
          getValueProps={(value) => {
            return {
              value: !!value,
            };
          }}
          normalize={(value) => {
            return value ? 1 : 0;
          }}
        >
          <Switch />
        </Form.Item>
        <Form.Item name="description" label="描述">
          <Input.TextArea rows={5}></Input.TextArea>
        </Form.Item>

        <Form.Item name="content" label="内容" initialValue={''}>
          <ReactQuill
            modules={{
              // https://github.com/noeloconnell/quill-image-uploader
              imageUploader: {
                upload: (file: any) => {
                  // return a Promise that resolves in a link to the uploaded image
                  return new Promise(async (resolve, reject) => {
                    const { url } = await commonApi.uploadFile(file);

                    resolve(url);
                  });
                },
              },
              toolbar: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                ['blockquote'],
                ['link', 'image'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                [{ align: [] }],
                ['clean'],
              ],
            }}
            theme="snow"
            className="max-w-5xl h-[50vh]"
          />
        </Form.Item>

        <Form.Item className="mt-20 flex justify-center">
          <Button type="primary" htmlType="submit">
            保存
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CmsArticleDetailPage;
