export const addressDetail = {
  hk: {
    line2: 'HONGKONG',
    line3: `Flat 1705, New World Tower 1, 16-18 Queen's Road Central, Central`,
  },
  jp: {
    line1: 'GINZA XIAOMA',
    line2: '東京都中央区銀座7-6-11',
    line3: 'ミクニ銀座 7 ビル1階',
    line4: '03-6264-5267',
    line5: '登録番号: T6011101072905',
  },
  sg: {
    line2: 'SINGAPORE',
    line3: `#23-05, Ngee Ann City Tower B, 391B Orchard Road`,
  },
};

export const weekDetail = {
  hk: ['Sun.', 'Mon.', 'Tue.', 'Wed.', 'Thu.', 'Fri.', 'Sat.'],
  jp: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
  sg: ['Sun.', 'Mon.', 'Tue.', 'Wed.', 'Thu.', 'Fri.', 'Sat.'],
};

export const dictionaries = {
  receipt_hk: 'Receipt',
  receipt_jp: '領 収 書',
  receipt_sg: 'Receipt',
  total_hk: 'Total',
  total_jp: '合計',
  total_sg: 'Total',
  tax_hk: '（Tax included）',
  tax_jp: '（内消費税 10%）',
  tax_sg: '（Tax included）',
  paid_hk: 'Paid',
  paid_jp: 'お預かり',
  paid_sg: 'Paid',
  change_hk: 'Change',
  change_jp: 'お釣り',
  change_sg: 'Change',
  accountable_hk: 'Accountable',
  accountable_jp: '担当者',
  accountable_sg: 'Accountable',
  footer_hk:
    'Please note the products purchased in store cannot be returned or exchanged.',
  footer_jp:
    '店頭でお買い上げいただきました商品の返品 · 交換はできません。ご了承ください。',
  footer_sg:
    'Please note the products purchased in store cannot be returned or exchanged.',
  use_points_hk: 'Use points',
  use_points_jp: 'ポイントを使用する',
  use_points_sg: 'Use points',
  earn_points_hk: 'Earn points',
  earn_points_jp: 'ポイントを獲得する',
  earn_points_sg: 'Earn points',
  remaining_points_hk: 'Remaining points',
  remaining_points_jp: '残りポイント',
  remaining_points_sg: 'Remaining points',
  discount_amount_hk: 'Discount amount',
  discount_amount_jp: '割引額',
  discount_amount_sg: 'Discount amount',
  email_hk: 'Email',
  email_jp: '会員',
  email_sg: 'Email',
  coupon_jp: 'クーポン',
  coupon_hk: 'Coupon',
  coupon_sg: 'Coupon',
  //
  _hk: '000',
  _jp: '000',
  _sg: '000',
};
