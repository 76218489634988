import { OmsRecycleOrderDetail } from 'types/oms';
import { UmsMember } from 'types/ums';
import styles from './index.module.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { fetchOmsRecyclingList } from 'apis/oms';
import usePagination from 'commons/hooks/usePagination';
import { PageQuery } from 'types/base';
import Table, { ColumnsType } from 'antd/es/table';
import showTotal from 'components/show-total';
import { Button, message } from 'antd';
import {
  OrderType,
  ShopMaping,
  getRecycleOrderStatusText,
} from 'constants/RecyclingConsignment';
import { useNavigate } from 'react-router-dom';
import i18n from '../../../i18n';
import LOCALS from '../../../commons/locals';
import useIsMobile from 'commons/hooks/useIsMobile';
import MobileList from 'components/descriptions-mobile-list';
import type { ColumnsProps } from 'components/descriptions-mobile-list';
import {
  getDempyouProduct,
  getDempyouOrderInfo,
  getDempyouToPrint,
  ProductType,
  PrintParamType,
} from 'utils/getDempyouParam';
import { useAppSelector } from 'store/hooks';
import { selectGlobalInfo } from 'store/slices/globalInfoSlice';
import { getProductLabel } from 'utils/getProductLabel';

const RecyclingConsignmentHistory = ({
  memberId,
}: {
  memberId: UmsMember['id'];
}) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [selectedRows, setSelectedRows] = useState<OmsRecycleOrderDetail[]>([]);
  const {
    staffSelectOptions,
    countryOptions,
    productCategoryCascaderOptions,
    colorSelectOptions,
    rankSelectOptions,
    stampSelectOptions,
    materialCascaderOptionsMap,
    hardwareSelectOptions,
    accessorySelectOptions,
  } = useAppSelector(selectGlobalInfo);

  const {
    loading,
    setLoading,
    pageNum,
    setPageNum,
    pageSize,
    setPageSize,
    total,
    setTotal,
    dataSource,
    setDataSource,
  } = usePagination<OmsRecycleOrderDetail>();

  const getDataSource = useCallback(
    async ({ pageNum, pageSize }: PageQuery) => {
      try {
        setLoading(true);
        const {
          data: { list, total },
        } = await fetchOmsRecyclingList({
          memberId,
          pageNum,
          pageSize,
        });
        setDataSource(list);
        setTotal(total || 0);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    },
    [memberId, setDataSource, setLoading, setTotal]
  );

  useEffect(() => {
    getDataSource({ pageNum: 1, pageSize: 10 });
  }, [getDataSource]);

  const getColumns = useCallback(() => {
    return [
      {
        title: i18n.t(LOCALS.code),
        key: 'code',
        dataIndex: 'code',
        width: 200,
        render: (_: OmsRecycleOrderDetail, record: OmsRecycleOrderDetail) => {
          if (!record) return;
          return (
            <Button
              type="link"
              onClick={() => {
                navigate(
                  `/rrs/recycling-consignment-detail/${record?.omsRecycleOrder?.id}`
                );
              }}
            >
              {record.omsRecycleOrder?.code}
            </Button>
          );
        },
      },
      {
        title: i18n.t(LOCALS.order_type),
        key: 'type',
        dataIndex: 'type',
        width: 150,
        render: (_: string, record: OmsRecycleOrderDetail) => {
          if (!record) return;
          let t = OrderType.find(
            (d) => record.omsRecycleOrder?.type === d.value
          );
          if (t) {
            return t.label;
          }
        },
      },
      {
        title: i18n.t(LOCALS.shop_id),
        dataIndex: 'shopid',
        width: 200,
        key: 'shopid',
        render: (_: string, record: OmsRecycleOrderDetail) => {
          if (!record) return;
          return ShopMaping[`${record.omsRecycleOrder?.storeId}`] || '-';
        },
      },
      {
        title: i18n.t(LOCALS.order_status),
        dataIndex: 'status',
        width: 200,
        key: 'status',
        render: (_: string, record: OmsRecycleOrderDetail) => {
          if (!record) return;
          return <div>{getRecycleOrderStatusText(record.omsRecycleOrder)}</div>;
        },
      },
    ];
  }, [navigate]);

  const columns: ColumnsType<OmsRecycleOrderDetail> = useMemo(
    () => getColumns(),
    [getColumns]
  );

  const mobColumns: ColumnsProps<OmsRecycleOrderDetail> = useMemo(
    () => getColumns(),
    [getColumns]
  );

  const forwardReceipt = useCallback(async () => {
    const b = selectedRows.every(
      (d) => d.omsRecycleOrder?.type === selectedRows[0].omsRecycleOrder?.type
    );
    if (!b) {
      message.warning(i18n.t('consignment_orders_and'));
      return;
    }
    const prodList: ProductType[] = [];
    let orderInfo: PrintParamType | undefined = undefined;
    orderInfo = await getDempyouOrderInfo(selectedRows[0], {
      staffSelectOptions,
      countryOptions,
    });
    selectedRows.forEach((d) => {
      const label = getProductLabel(
        {
          productCategoryCascaderOptions,
          colorSelectOptions,
          rankSelectOptions,
          stampSelectOptions,
          materialCascaderOptionsMap,
          hardwareSelectOptions,
          accessorySelectOptions,
        },
        {
          attrAccessory: d.omsRecycleOrderProduct?.accessory,
          attrColor: d.omsRecycleOrderProduct?.attrColor,
          attrHardware: d.omsRecycleOrderProduct?.attrHardware,
          attrMaterial: d.omsRecycleOrderProduct?.attrMaterial,
          attrSize: d.omsRecycleOrderProduct?.attrSize,
          attrStamp: d.omsRecycleOrderProduct?.attrStamp,
          productCategoryId: d.omsRecycleOrderProduct?.productCategoryId,
        }
      );
      // 「商品名　尺寸　素材　色　刻印　金具」
      const name = `${label.productCategorySelectLabelList} ${label.materialSelectLabelList} ${label.colorSelectLabelList} ${label.stampSelectLabel} ${label.hardwareSelectLabel}`;
      prodList.push(getDempyouProduct(d, { name })!);
    });
    if (prodList && orderInfo) {
      getDempyouToPrint({
        productList: prodList,
        printParam: orderInfo,
        prints: true,
      });
    }
  }, [
    accessorySelectOptions,
    colorSelectOptions,
    countryOptions,
    hardwareSelectOptions,
    materialCascaderOptionsMap,
    productCategoryCascaderOptions,
    rankSelectOptions,
    selectedRows,
    staffSelectOptions,
    stampSelectOptions,
  ]);

  return (
    <div>
      <div className={`${styles.title} flex`}>
        <div className="w-1/2">
          {i18n.t(LOCALS.recycling_consignment_order_history)}
        </div>
        <div className="w-1/2 text-right">
          <Button
            type="primary"
            disabled={!selectedRows.length}
            onClick={() => forwardReceipt()}
          >
            伝票印刷
          </Button>
        </div>
      </div>

      {isMobile ? (
        <MobileList
          columns={mobColumns}
          dataSource={dataSource}
          loading={loading}
          pagination={{
            simple: true,
            total,
            pageSize,
            current: pageNum,
            onChange: (page, pageSize) => {
              setPageNum(page);
              setPageSize(pageSize);
              getDataSource({ pageNum: page, pageSize });
            },
          }}
        />
      ) : (
        <Table
          bordered
          pagination={{
            showTotal,
            total,
            pageSize,
            current: pageNum,
            onChange: (page, pageSize) => {
              setPageNum(page);
              setPageSize(pageSize);
              getDataSource({ pageNum: page, pageSize });
            },
          }}
          rowSelection={{
            selectedRowKeys: selectedRows.map(
              (d) => d.omsRecycleOrder?.code
            ) as React.Key[],
            onChange: (_, selectedRows) => setSelectedRows(selectedRows),
            getCheckboxProps: (record: OmsRecycleOrderDetail) => ({
              disabled: !record.omsRecycleOrderLogistics?.country,
            }),
          }}
          loading={loading}
          rowKey={(e) => e.omsRecycleOrder?.code || ''}
          style={{
            marginTop: 12,
          }}
          dataSource={dataSource}
          columns={columns}
        />
      )}
    </div>
  );
};

export default RecyclingConsignmentHistory;
