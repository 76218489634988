import Avatar from 'react-avatar'
import { sessionContent } from './helpers/sessionContentHelper'
import { useTranslation } from 'react-i18next'
import ReactTimeAgo from 'react-time-ago'
import { useDispatch, useSelector } from 'react-redux';
import {
  resetSession,
  setSelectedCustomer,
  setSelectedSession
} from '../../store/im-chat-stores/imManagerSettingsSlice';

export const CustomerSession = ({session}) => {
  const { t : translation} = useTranslation()
  const { lastMessage } = sessionContent(session, translation)
  const locale = useSelector(state => state.imManagerSettings.locale)
  const selectedSession = useSelector(state => state.imManagerSettings.selectedSession)
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(resetSession())
    dispatch(setSelectedCustomer(session))
    dispatch(setSelectedSession(session))
  }

  return (
    <div
      className={`mb-2 p-[12px] hover:bg-white bg-[#EBEDF7] hover:shadow-md rounded-lg flex flex-col space-y-[8px] cursor-pointer ${selectedSession?.id === session?.id ? 'bg-white shadow-md' : ''}`}
      onClick={handleClick}
    >
      <div className="flex items-center space-x-2">
        <Avatar
          size={40}
          name={session?.userName || 'Unknown User'}
          round={true}
        />
        <div className="grow flex flex-col justify-start space-y-2">
          <div className="flex justify-between items-center">
            <p className={`text-[14px] mb-0 ${session?.unRead > 0 ? 'font-bold' : ''}`}>{session?.userName || 'Unknown User'}</p>
            {session?.unRead > 0 && <div className="ml-1 h-2 w-2 bg-blue-500 rounded-full"/>}
          </div>
          <div className="flex justify-between items-center space-x-4">
            <p className="text-[10px] mb-0 text-[#797D91] overflow-hidden truncate">ID {session?.userId}</p>
            <p className="text-[12px] mb-0 text-[#9EA1B5]">
              <ReactTimeAgo
                timeStyle="twitter"
                date={new Date(session?.latestImMessage?.createdAt || session?.createdAt)}
                locale={locale}
              />
            </p>
          </div>
        </div>
      </div>
      <p className={`text-[#797D91] mb-0 text-[12px] grow overflow-hidden truncate ${session?.unRead > 0 ? 'font-bold' : ''}`}>
        {lastMessage}
      </p>
    </div>
  )
}
