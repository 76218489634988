import { useState, useCallback, useEffect, useMemo } from 'react';
import { PmsProductWithPriceType } from 'types/pms';
import {
  Button,
  Form,
  notification,
  Row,
  Col,
  Radio,
  Divider,
  message,
  Spin,
  Popconfirm,
  Input,
} from 'antd';
import { financialManagementAccountList } from 'apis/fms';
import { omsCreateCheckoutCounter, omsStoreConfirmOrder } from 'apis/oms';
import { FmsAccountManagement } from 'types/fms';
import {
  StoreConfirmOrder,
  StoreCreateOrderDto,
  StoreConfirmOrderDto,
  ReceiptDto,
} from 'types/oms';
import {
  CURRENCY_OPTION_LIST,
  CURRENCY_MAP,
  SG_SHOP_LIST,
  SHOP_MAP,
} from 'commons/options';
import PaymentInput from './payment-input';
import ChangePriceModal from './change-price-modal';
import { useToggle } from 'react-use';
import { usePayloadContext } from 'pages/oms/checkout-counter/utils/payload-context';
import { ActionType } from 'pages/oms/checkout-counter/utils/payload-reducer';
import { thousands } from 'utils/tools';
import { useAppSelector } from 'store/hooks';
import { selectUserInfo } from 'store/slices/userInfoSlice';
import LOCALS from '../../../commons/locals';
import i18n from 'i18next';
import { debounce, sumBy } from 'lodash-es';

type Props = {
  className?: string;
  productList: PmsProductWithPriceType[];
  onReset: () => void;
  orderResult?: StoreConfirmOrder;
  setOrderResult: (orderResult: StoreConfirmOrder | undefined) => void;
};

export interface CategoryType {
  [key: string]: FmsAccountManagement[];
}

const PaymentInfo = ({
  productList,
  onReset,
  orderResult,
  setOrderResult,
}: Props) => {
  const [form] = Form.useForm<{
    priceList?: {
      payType: string;
      payAmount?: number | null;
    }[];
  }>();
  const [, contextHolder] = notification.useNotification();
  const formValuesPriceList = Form.useWatch((values) => values.priceList, form);
  const [saveLoading, toggleSaveLoading] = useToggle(false);
  const [accountList, setAccountList] = useState<FmsAccountManagement[]>([]);
  const [isChangePrice, toggleIsChangePrice] = useToggle(false);
  const [totalCountLoading, toggleTotalCountLoading] = useToggle(false);
  const { dispatch, state } = usePayloadContext();
  const { useIntegration, payMode, promotionAmount, couponCode, createdFrom } =
    state;
  const { shop = SHOP_MAP.GINZA } = useAppSelector(selectUserInfo);

  const resetPaymentForm = useCallback(
    (accountList: FmsAccountManagement[]) => {
      if (accountList && accountList.length) {
        form.setFieldValue(
          'priceList',
          accountList.slice(0, 4).map(({ accountCode }) => {
            return {
              payType: accountCode,
            };
          })
        );
      }
    },
    [form]
  );

  useEffect(() => {
    financialManagementAccountList(createdFrom)
      .then((res) => {
        setAccountList(res);
        resetPaymentForm(res);
      })
      .finally(() => {});
  }, [createdFrom, resetPaymentForm]);

  // 币种
  const currency = useMemo(() => {
    if (createdFrom) {
      return CURRENCY_OPTION_LIST[createdFrom - 1].value;
    }
    return CURRENCY_MAP.JPY;
  }, [createdFrom]);

  // 接口获取合计金额
  const debounceGetTotalCount = useMemo(() => {
    return debounce((payload: StoreConfirmOrderDto) => {
      toggleTotalCountLoading(true);
      omsStoreConfirmOrder(payload)
        .then((d) => {
          toggleTotalCountLoading(false);
          setOrderResult(d);
        })
        .catch(() => {})
        .finally(() => {});
    }, 300);
  }, [setOrderResult, toggleTotalCountLoading]);
  const getTotalCount = useCallback(
    (payload: StoreConfirmOrderDto) => {
      debounceGetTotalCount(payload);
    },
    [debounceGetTotalCount]
  );

  // 需要支付的金额
  const amountNeedToPay = useMemo(() => {
    return orderResult?.omsOrder.payAmountActualCurrency || 0;
  }, [orderResult]);

  // 已收款的金额
  const receivedAmount = useMemo(() => {
    if (!formValuesPriceList) return 0;
    return sumBy(formValuesPriceList, (i) => i.payAmount || 0);
  }, [formValuesPriceList]);

  // 优惠金额
  const preferentialAmount = useMemo(() => {
    if (orderResult?.omsOrder) {
      const { totalAmountActualCurrency, payAmountActualCurrency } =
        orderResult?.omsOrder;
      return totalAmountActualCurrency - payAmountActualCurrency;
    }
    return 0;
  }, [orderResult?.omsOrder]);

  // 找零金额
  const changeAmount = useMemo(() => {
    if (!formValuesPriceList) return 0;

    return receivedAmount - amountNeedToPay;
  }, [formValuesPriceList, receivedAmount, amountNeedToPay]);

  // 计算总价格
  useEffect(() => {
    if (state.productList.length) {
      // 产地判断
      const stockPlace = state.productList[0].stockPlace || '';

      // 新加披 特殊处理
      if (SG_SHOP_LIST.includes(stockPlace)) {
        if (
          !state.productList.every((d) =>
            SG_SHOP_LIST.includes(d.stockPlace || '')
          )
        ) {
          message.warning({
            content: i18n.t(LOCALS.stock_place_warning_2),
            key: 'stockPlace',
          });
          return;
        }
      } else {
        if (!state.productList.every((d) => d.stockPlace === stockPlace)) {
          message.warning({
            content: i18n.t(LOCALS.stock_place_warning_2),
            key: 'stockPlace',
          });
          return;
        }
      }

      getTotalCount({
        useIntegration,
        promotionAmount,
        productList: state.productList,
        couponCode,
        memberId: state.memberId,
      });
    } else {
      setOrderResult(undefined);
    }
  }, [
    useIntegration,
    promotionAmount,
    state.productList,
    getTotalCount,
    couponCode,
    shop,
    state.memberId,
    setOrderResult,
  ]);

  // 确认收款
  const onConfirm = useCallback(() => {
    const t = form.getFieldsValue();
    if (!t.priceList) return;

    const paymentList = t.priceList.filter(
      ({ payAmount }) => payAmount && payAmount > 0
    ) as {
      payType: string;
      payAmount: number;
    }[];
    const payload: StoreCreateOrderDto = {
      ...state,
      paymentList,
    };
    // 验证字段
    const { staffName, productList, payMode } = state;
    if (!staffName) {
      message.warning(i18n.t(LOCALS.select_representative));
      return;
    }
    if (productList.length === 0) {
      message.warning(i18n.t(LOCALS.select_items));
      return;
    }

    // 验证商品币种一致
    if (
      !productList.every(
        (product) => product.currency === productList[0].currency
      )
    ) {
      message.warning(i18n.t(LOCALS.stock_place_warning_2));
      return;
    }

    if (payMode === 0 && changeAmount < 0) {
      message.warning('非定金订单必须全额支付');
      return;
    }
    if (payMode === 1 && changeAmount >= 0) {
      message.warning('定金订单，不能全款支付');
      return;
    }

    toggleSaveLoading(true);
    omsCreateCheckoutCounter(payload)
      .then((d) => {
        message.success('创建成功！');
        toggleSaveLoading(false);
        const {
          omsOrder: { orderSn, integration, createTime },
        } = d;
        const { useIntegration, promotionAmount, createdFrom, staffName } =
          state;

        // 小票所需参数
        const param: ReceiptDto = {
          useIntegration,
          promotionAmount,
          createdFrom,
          staffName,
          orderSn,
          integration,
          createTime,
          totalAmountActualCurrency:
            orderResult?.omsOrder.totalAmountActualCurrency || 0,
          payAmountActualCurrency:
            orderResult?.omsOrder.payAmountActualCurrency || 0,
          totalTaxAmount: orderResult?.omsOrder.totalTaxAmount,
          omsOrderItems:
            orderResult?.omsOrderItems.map(
              ({
                productSn,
                productPrice,
                productBrand,
                isTaxFree,
                productName,
                productId,
              }) => {
                return {
                  productSn: productSn || '',
                  productPrice,
                  productBrand: productBrand || '',
                  isTaxFree,
                  productName:
                    state.productList.find((i) => i.id === productId)?.name ||
                    productName ||
                    '',
                };
              }
            ) || [],
          couponAmount: orderResult?.omsOrder.couponAmount || 0,
          receivedAmount,
          changeAmount,
        };
        const encode = encodeURIComponent(JSON.stringify(param));
        window.open(`/prints/receipt?body=${encode}`);
      })
      .catch((d) => {})
      .finally(() => {
        toggleSaveLoading(false);
      });
  }, [
    form,
    state,
    changeAmount,
    toggleSaveLoading,
    orderResult?.omsOrder.totalAmountActualCurrency,
    orderResult?.omsOrder.payAmountActualCurrency,
    orderResult?.omsOrder.totalTaxAmount,
    orderResult?.omsOrder.couponAmount,
    orderResult?.omsOrderItems,
    receivedAmount,
  ]);

  // 重置
  const reset = () => {
    onReset();
    setOrderResult(undefined);
    dispatch({
      type: ActionType.RESET,
    });
  };

  return (
    <div className="flex flex-col justify-center items-center">
      {/* 结算细节 */}
      <div className="mt-4 w-1/2">
        <div className="mb-5 flex items-center justify-between">
          <div>
            {i18n.t(LOCALS.total)}
            <span className="text-red-500 text-xl">
              &nbsp;{productList.length}&nbsp;
            </span>
            {i18n.t(LOCALS.product)}，{i18n.t(LOCALS.discounted)} {currency}{' '}
            {thousands(preferentialAmount)}
          </div>
          <Button onClick={toggleIsChangePrice}>
            {i18n.t(LOCALS.price_adjustment)}
          </Button>
        </div>
        <div className="form">
          <Form
            form={form}
            initialValues={{ priceList: [] }}
            onFinish={async (value) => {}}
          >
            <Form.List name="priceList">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item required={false} key={field.key}>
                      <Row justify={'center'}>
                        <Col span={24}>
                          <Form.Item
                            {...field}
                            validateTrigger={['onChange', 'onBlur']}
                            noStyle
                          >
                            <PaymentInput accountList={accountList} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                  ))}
                </>
              )}
            </Form.List>
          </Form>
        </div>
        <div className="btn grid grid-cols-2 gap-6">
          <Popconfirm
            title={i18n.t(LOCALS.caution)}
            description={
              <div className="w-[200px]">
                {i18n.t(LOCALS.confirm_submission)}
              </div>
            }
            onConfirm={() => {
              resetPaymentForm(accountList);
            }}
            okText={i18n.t(LOCALS.confirm)}
            cancelText={i18n.t(LOCALS.cancel)}
          >
            <Button>{i18n.t(LOCALS.clear_payment_data)}</Button>
          </Popconfirm>
          <Popconfirm
            title={i18n.t(LOCALS.caution)}
            description={
              <div className="w-[200px]">
                {i18n.t(LOCALS.confirm_submission)}
              </div>
            }
            onConfirm={() => reset()}
            okText={i18n.t(LOCALS.confirm)}
            cancelText={i18n.t(LOCALS.cancel)}
          >
            <Button>{i18n.t(LOCALS.clear_order)}</Button>
          </Popconfirm>
        </div>

        {/* 优惠明细 */}
        {contextHolder}
        <Divider />
      </div>
      {/* 合计金额 */}
      <div className="mt-4 w-1/2">
        <div className="flex justify-between items-center mb-5">
          <div>
            <Radio.Group
              optionType="button"
              buttonStyle="solid"
              value={payMode}
              onChange={(e) => {
                dispatch({
                  type: ActionType.UPDATE_BATCH,
                  payload: {
                    payMode: e.target.value,
                  },
                });
              }}
            >
              <Radio value={0}>{i18n.t(LOCALS.non_deposit)}</Radio>
              <Radio value={1}>{i18n.t(LOCALS.deposit)}</Radio>
            </Radio.Group>
          </div>
          <Spin spinning={totalCountLoading}>
            <div className="text-lg w-52">
              <div className="flex justify-between mb-2">
                <div>{i18n.t(LOCALS.total_amount)}</div>
                <div>
                  <span>{`${currency} ${amountNeedToPay.toLocaleString()}`}</span>
                </div>
              </div>
              <div className="flex justify-between mb-2">
                <span>{i18n.t(LOCALS.PVkdenXCwb)}</span>
                <span>{`${currency} ${receivedAmount.toLocaleString()}`}</span>
              </div>
              <div className="flex justify-between">
                <span>{i18n.t(LOCALS.AYTHzloOiY)}</span>
                <span>{`${currency} ${changeAmount.toLocaleString()}`}</span>
              </div>
            </div>
          </Spin>
        </div>

        <div className="mb-4">
          <Input.TextArea
            value={state.note}
            onChange={(e) => {
              dispatch({
                type: ActionType.UPDATE_BATCH,
                payload: { note: e.target.value },
              });
            }}
            placeholder={i18n.t(LOCALS.please_enter_remark) || ''}
            rows={4}
            maxLength={250}
          ></Input.TextArea>
        </div>
        <div>
          <Button
            className="w-full"
            type="primary"
            onClick={onConfirm}
            loading={saveLoading}
            disabled={
              totalCountLoading ||
              !productList.length ||
              !orderResult ||
              (payMode === 0 && changeAmount < 0) || // 非定金订单必须全额支付
              (payMode === 1 && changeAmount >= 0) // 定金订单，不能全款支付
            }
          >
            {i18n.t(LOCALS.confirm)}
          </Button>
        </div>
      </div>

      {/* 改价弹窗 */}
      {isChangePrice && (
        <ChangePriceModal
          orderResult={orderResult}
          open={isChangePrice}
          onCancel={toggleIsChangePrice}
          currency={currency}
          totalPrice={orderResult?.omsOrder.totalAmountActualCurrency || 0}
        />
      )}
    </div>
  );
};

export default PaymentInfo;
