import {Typography} from 'antd'
import {useTranslation} from "react-i18next"
import { CustomerSessionList } from './CustomerSessionList';
import { useCallback, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { insertMessage, resetSession } from '../../store/im-chat-stores/imManagerSettingsSlice';
import { imManagerChatApi } from '../../store/im-chat-stores/imManagerChatApi';
import { useDispatch, useSelector } from 'react-redux';

const { Title } = Typography

const CustomerInbox = () => {
  const {t} = useTranslation()
  const pluginKey = useSelector(state => state.imManagerSettings.pluginKey)
  const baseUrl = useSelector(state => state.imManagerSettings.baseUrl)
  const authToken = useSelector(state => state.imManagerSettings.authToken)
  const dispatch = useDispatch()
  const [socket, setSocket] = useState(null)
  const [isConnected, setIsConnected] = useState(false)
  const [connectionError, setConnectionError] = useState(false)

  const initializeSocket = useCallback(() => {
    const _socket = io(`${baseUrl}/im-chat`, {transports: ['websocket']})

    const handleDisconnect = () => {
      setIsConnected(false)
    }

    const handleAuthenticated = (data) => {
      console.log('authenticated', data)
    }

    const handleJoined = () => {
      setIsConnected(true)
    }

    const handleError = (error) => {
      console.log('socket_connection_error', error)
      setConnectionError(true)
    }

    const handleMessageCreated = (new_message) => {
      dispatch(resetSession())
      dispatch(insertMessage([new_message]))
      dispatch(imManagerChatApi.util.invalidateTags(['CustomerList']))
    }

    _socket.on('connect', () => {})
    _socket.on('reconnect', () => {})
    _socket.on('disconnect', handleDisconnect)
    _socket.on('authenticated', handleAuthenticated)
    _socket.on('joined', handleJoined)
    _socket.on("error",(data) => handleError(data))
    _socket.on('message_created', handleMessageCreated)

    setSocket(_socket)

    const pingInterval = setInterval(() => {
      _socket.emit('ping')
    }, 3000)

    return () => {
      _socket.disconnect()
      clearInterval(pingInterval)
    }
  }, [baseUrl])

  const sendAuthorization = useCallback(
    (socket) => {
      if (socket) {
        socket.emit('authentication', {
          personType: 'manager',
          token: authToken,
          imChannelPluginKey: pluginKey,
        })
      }
    },
    [authToken, pluginKey]
  )

  useEffect(() => {
    if(baseUrl){
      initializeSocket()
    }
  }, [baseUrl, authToken, pluginKey])

  useEffect(() => {
    if (socket) {
      sendAuthorization(socket)
    }
  }, [socket, sendAuthorization])

  return (
    <div className="flex justify-center flex-col h-[100%]">
      <Title className="!h-[8%] !p-2 !flex !items-center !m-0 !cursor-default !text-[20px]">
        {t('inbox')}
      </Title>
      <CustomerSessionList/>
    </div>
  )
}

export default CustomerInbox