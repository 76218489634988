import React, { useState, useEffect, useCallback } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import { SessionDetails } from './SessionDetails'
import { SkeletonLoader } from './loader/SkeletonLoader'

export const SessionList = () => {
  const [sessions, setSessions] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [page, setPage] = useState(1)
  const authToken = useSelector(state => state.imManagerSettings.authToken)
  const baseUrl = useSelector(state => state.imManagerSettings.baseUrl)
  const customer = useSelector((state) => state.imManagerSettings.selectedCustomer)

  const fetchData = useCallback(async () => {
    try {
      const response = await fetch(`${baseUrl}/im-chat/manager/im-chat-list?pageNum=${page}&status=1&pageSize=20&userId=${customer.userId}`, {
        headers: {
          'Authorization': `Bearer ${authToken}`
        }
      })
      const sessionData = await response.json()
      if (sessionData.list.length > 0) {
        setSessions(prevSessions => [...prevSessions, ...sessionData?.list])
        setPage(prevPage => prevPage + 1)
      }
      if(sessions.length === sessionData.total) {
        setHasMore(false)
      }
    } catch (error) {
      console.error('Failed to fetch data:', error)
    }
  }, [page])

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div
      className="flex flex-col gap-2 im-chat-scrollbar overflow-y-auto !h-[85%] mt-0 px-1"
      id="scrollableDiv"
    >
      <InfiniteScroll
        dataLength={sessions.length}
        next={fetchData}
        hasMore={hasMore}
        loader={<SkeletonLoader/>}
        scrollableTarget="scrollableDiv"
      >
        {sessions.map(session => <SessionDetails session={session} key={session.id}/>)}
      </InfiniteScroll>
    </div>
  )
}
