import { Descriptions } from 'antd';
import {
  PUBLISH_STATUS_OPTION_LIST,
  STOCK_PLACE_OPTION_LIST,
  findLabelByValue,
} from 'commons/options';
import { Link } from 'react-router-dom';
import { PmsProduct } from 'types/pms';
import { thousands } from 'utils/tools';
import { CloseOutlined } from '@ant-design/icons';
import i18n from '../../../i18n';
import LOCALS from '../../../commons/locals';

interface Props {
  productList: PmsProduct[];
  onDelete: (id: PmsProduct['id']) => void;
}

const ProductList = ({ productList, onDelete }: Props) => {
  return (
    <div>
      {productList.map((product) => {
        const {
          pic,
          id,
          name,
          productSn,
          price,
          currency,
          stockPlace,
          publishStatus,
          stock,
        } = product;

        return (
          <div
            key={id}
            className="w-full p-2 mb-2 rounded border border-gray-200 flex items-center relative"
          >
            <button
              className="absolute top-0 right-0 p-2 bg-gray-300 text-white rounded-tr"
              onClick={() => {
                onDelete(id);
              }}
            >
              <CloseOutlined />
            </button>
            <img
              src={pic}
              alt={name}
              className="w-24 h-24 lg:w-32 lg:h-32 shrink-0 mr-4"
            />

            <Descriptions size="small">
              <Descriptions.Item label={i18n.t(LOCALS.product_sn) || '商品编号'}>
                <Link to={`/pms/product-view/${id}`} target="_blank">
                  {productSn}
                </Link>
              </Descriptions.Item>
              <Descriptions.Item label={i18n.t(LOCALS.product_name) || '商品名称'}>{name}</Descriptions.Item>
              <Descriptions.Item label={i18n.t(LOCALS.price) || '商品价格'}>
                {`${currency} ${thousands(price)}`}
              </Descriptions.Item>
              <Descriptions.Item label={i18n.t(LOCALS.stock_place) || '库存地'}>
                {findLabelByValue(stockPlace, STOCK_PLACE_OPTION_LIST)}
              </Descriptions.Item>
              <Descriptions.Item label={i18n.t(LOCALS.publish_status) || '上架状态'}>
                {findLabelByValue(publishStatus, PUBLISH_STATUS_OPTION_LIST)}
                {` / ${stock}`}
              </Descriptions.Item>
            </Descriptions>
          </div>
        );
      })}
    </div>
  );
};

export default ProductList;
