import { Descriptions } from 'antd';
import { useCityList } from 'apis/home';
import LOCALS from 'commons/locals';
import { findLabelByValue } from 'commons/options';
import CopyButton from 'components/copy-button';
import { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { selectGlobalInfo } from 'store/slices/globalInfoSlice';
import { OmsOrderDetail } from 'types/oms';
import { useDescProps } from 'commons/hooks/useDescProps';

type RecipientInformationProps = {
  omsOrderDetail: OmsOrderDetail;
};

const RecipientInformation = ({
  omsOrderDetail: {
    receiverName,
    receiverPhone,
    receiverPostCode,
    receiverProvince,
    receiverCity,
    receiverDetailAddress,
  },
}: RecipientInformationProps) => {
  const { countryOptions } = useAppSelector(selectGlobalInfo);
  const cityList = useCityList(receiverProvince);

  const targetCity = useMemo(() => {
    return cityList.find((i) => i.code === receiverCity);
  }, [cityList, receiverCity]);

  const recipientInformationText = useMemo(() => {
    return `${receiverName} ${
      receiverPhone.startsWith('+') ? receiverPhone : `+${receiverPhone}`
    } ${receiverPostCode} ${receiverDetailAddress} ${
      targetCity ? targetCity.name : receiverCity
    } ${findLabelByValue(receiverProvince, countryOptions)}`;
  }, [
    countryOptions,
    receiverCity,
    receiverDetailAddress,
    receiverName,
    receiverPhone,
    receiverPostCode,
    receiverProvince,
    targetCity,
  ]);

  return (
    <Descriptions
      title={
        <div className="flex items-center">
          <Trans i18nKey={LOCALS.recipient_information} />
          <CopyButton copyText={recipientInformationText}></CopyButton>
        </div>
      }
      bordered
      {...useDescProps({})}
    >
      <Descriptions.Item label={<Trans i18nKey={LOCALS.name} />}>
        {receiverName}
      </Descriptions.Item>

      <Descriptions.Item label={<Trans i18nKey={LOCALS.phone_number} />}>
        {receiverPhone}
      </Descriptions.Item>

      <Descriptions.Item label={<Trans i18nKey={LOCALS.zip_code} />}>
        {receiverPostCode}
      </Descriptions.Item>

      <Descriptions.Item label={<Trans i18nKey={LOCALS.country_region} />}>
        {findLabelByValue(receiverProvince, countryOptions)}
      </Descriptions.Item>

      <Descriptions.Item label={<Trans i18nKey={LOCALS.state_province_city} />}>
        {targetCity ? targetCity.name : receiverCity}
      </Descriptions.Item>

      <Descriptions.Item label={<Trans i18nKey={LOCALS.detail_address} />}>
        {receiverDetailAddress}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default RecipientInformation;
