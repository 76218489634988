import { useState, useEffect, useMemo } from 'react';
import { Button, Form, Row, Col, Modal, message, Popconfirm } from 'antd';
import { financialManagementAccountList } from 'apis/fms';
import { omsStoreFinalPayment } from 'apis/oms';
import { FmsAccountManagement } from 'types/fms';
import PaymentInput from '../checkout-counter/payment-input';
import { thousands } from 'utils/tools';
import { OmsOrderDetail } from 'types/oms';
import { useAppSelector } from 'store/hooks';
import { selectUserInfo } from 'store/slices/userInfoSlice';
import i18n from 'i18n';
import LOCALS from 'commons/locals';
import { Trans } from 'react-i18next';
import { sumBy } from 'lodash-es';

interface Props {
  open: boolean;
  onCancel: (b: boolean) => void;
  omsOrderDetail: OmsOrderDetail;
}

export interface CategoryType {
  [key: string]: FmsAccountManagement[];
}

const FinalSettlement = ({ open, onCancel, omsOrderDetail }: Props) => {
  const [form] = Form.useForm<{
    priceList?: {
      payType: string;
      payAmount?: number | null;
    }[];
  }>();
  const formValuesPriceList = Form.useWatch((values) => values.priceList, form);
  const [accountList, setAccountList] = useState<FmsAccountManagement[]>([]);
  // 订单详情
  const { confirmedPayAmount, payAmountActualCurrency, orderItemList } =
    omsOrderDetail;
  const { shop } = useAppSelector(selectUserInfo);

  useEffect(() => {
    if (shop) {
      financialManagementAccountList(shop)
        .then((res) => {
          const accountList = res;
          setAccountList(res);

          if (accountList && accountList.length) {
            form.setFieldValue(
              'priceList',
              accountList.slice(0, 4).map((i) => {
                return {
                  payType: i.accountCode,
                };
              })
            );
          }
        })
        .finally(() => {});
    }
  }, [form, shop]);

  /** 剩余总价格 */
  const totalCount = useMemo(() => {
    if (payAmountActualCurrency && confirmedPayAmount) {
      return payAmountActualCurrency - confirmedPayAmount;
    }
    return 0;
  }, [confirmedPayAmount, payAmountActualCurrency]);

  const currency = useMemo(() => {
    if (orderItemList && orderItemList[0]) {
      return orderItemList[0].actualCurrency;
    }
    return '';
  }, [orderItemList]);

  const remainingMoney = useMemo(() => {
    if (!formValuesPriceList) return 0;

    const diff =
      totalCount - sumBy(formValuesPriceList, (i) => i.payAmount || 0);

    if (diff < 0) {
      message.warning('剩余金额错误');
    }

    return diff;
  }, [formValuesPriceList, totalCount]);

  const onFinish = () => {
    const t = form.getFieldsValue();
    const paymentList = t.priceList?.filter(
      (i) => i.payAmount && i.payAmount > 0
    ) as {
      payType: string;
      payAmount: number;
    }[];

    if (!paymentList) return;

    if (paymentList.length === 0) {
      message.warning('请填写一项收款内容');
      return;
    }

    const payload = {
      orderId: omsOrderDetail.id,
      paymentList,
    };
    omsStoreFinalPayment(payload)
      .then(() => {
        message.success(i18n.t(LOCALS.successful_operation));
        window.location.reload();
      })
      .catch((d) => {})
      .finally(() => {});
  };

  return (
    <Modal
      title={<Trans i18nKey={LOCALS.final_payment_settlement} />}
      width={600}
      open={open}
      onCancel={() => onCancel(false)}
      footer={[
        <Button onClick={() => onCancel(false)} key={'cancel'}>
          {i18n.t(LOCALS.cancel)}
        </Button>,
        <Popconfirm
          title={i18n.t(LOCALS.caution)}
          key={'ok'}
          description={
            <div className="w-[200px]">{i18n.t(LOCALS.confirm_submission)}</div>
          }
          onConfirm={() => onFinish()}
          okText={i18n.t(LOCALS.confirm)}
          cancelText={i18n.t(LOCALS.cancel)}
        >
          <Button
            type="primary"
            onClick={() => form.validateFields()}
            disabled={remainingMoney < 0}
          >
            {i18n.t(LOCALS.confirm)}
          </Button>
        </Popconfirm>,
      ]}
    >
      <div className="text-sm mb-2 text-center text-[red]">
        {i18n.t('total_amount')}：
        <span>
          {currency} {thousands(totalCount)}
        </span>
      </div>
      <div className="form">
        <Form form={form}>
          <Form.List name="priceList">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item required={false} key={field.key}>
                    <Row justify={'center'}>
                      <Col span={22}>
                        <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          noStyle
                        >
                          <PaymentInput
                            accountList={accountList}
                            max={totalCount}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                ))}
              </>
            )}
          </Form.List>
        </Form>
      </div>
      <div className="text-sm mb-2 text-center text-[red]">
        {i18n.t('remaining_amount')}：
        <span>
          {currency} {thousands(remainingMoney)}
        </span>
      </div>
    </Modal>
  );
};

export default FinalSettlement;
