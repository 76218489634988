import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'

const customBaseQuery = async (args, api, extraOptions) => {
  const { getState } = api;
  const state = getState();
  const {baseUrl, authToken} = state.imManagerSettings;
  const url = `${baseUrl}/im-chat/manager${args.url}`;

  const headers = {
    ...args.headers,
    Authorization: authToken,
  };

  return fetchBaseQuery({
    baseUrl: '',
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${authToken}`);
      return headers;
    },
  })({
    ...args,
    url,
    headers,
  }, api, extraOptions);
};

const sortSessions = (data) => {
  const sortedData = data.sort((a, b) => {
    const getSortTimestamp = (item) => {
      if (item.latestImMessage && item.latestImMessage.createdAt) {
        return new Date(item.latestImMessage.createdAt).getTime()
      }
      return new Date(item.createdAt).getTime()
    }

    return getSortTimestamp(b) - getSortTimestamp(a)
  })

  const uniqueSessionsMap = new Map()

  sortedData.forEach(item => {
    if (!uniqueSessionsMap.has(item.userId)) {
      uniqueSessionsMap.set(item.userId, item)
    }
  })

  return Array.from(uniqueSessionsMap.values())
}

export const imManagerChatApi = createApi({
  reducerPath: 'imManagerChatApi',
  baseQuery: retry(customBaseQuery, { maxRetries: 10 }),
  tagTypes: ['CustomerSessions', 'SessionMessages', 'CustomerList'],
  endpoints: (builder) => ({
    getSessions: builder.query( {
      query: ({pageNum, pageSize, pluginKey}) => ({
          url: `/im-chat-list`,
          method: 'GET',
          params: {
            pluginKey,
            pageNum,
            pageSize
          }
        }
      ),
      // transformResponse: (response) => {
      //   return sortSessions(response?.list)
      // },
      providesTags: ["CustomerList"],
      // keepUnusedDataFor: 0,
    }),

    getSessionMessages: builder.query({
      query: ({session}) => ({
          url: `/im-chat-messages/${session.id}`,
          method: 'GET'
        }
      ),
      providesTags: (result, error, arg) => [{ type: 'SessionMessages', id: arg.session.id }],
    }),

    getCustomerSessions: builder.query({
      query: ({ pluginKey, pageNum, status, pageSize, userId }) => ({
        url: '/im-chat-list',
        method: 'GET',
        params: {
          pluginKey,
          pageNum,
          pageSize,
          userId,
          status
        }
      }),
      transformResponse: (response) => {
        return response
      },
      providesTags: ['CustomerSessions'],
      keepUnusedDataFor: 0,
    }),

    getCustomerDetails: builder.query({
      query: ({session}) => {
        return {
          url: `/im-chat-customer-detail/${session.id}`,
          method: "GET"
        }
      },
      keepUnusedDataFor: 0
    }),

    updateCustomerDetails: builder.mutation({
      query: ({session, data}) => {
        return {
          url: `/im-chat-customer-detail/${session.id}`,
          method: "POST",
          body: data
        }
      }
    }),

    createMessage: builder.mutation({
      query: ({session, data}) => {
        return {
          url: `/im-chat-send-message/${session.id}`,
          method: "POST",
          body: data
        }
      }
    }),

    readChat: builder.mutation({
      query: ({session}) => {
        return {
          url: `/im-chat-read/${session.id}`,
          method: "POST"
        }
      }
    }),

    uploadAttachment: builder.mutation({
      query: ({file}) => {
        const formData = new FormData()
        formData.append('file', file)
        return {
          url: `/upload-file`,
          method: "POST",
          body: formData,
          formData: true
        }
      }
    }),

    createProfile: builder.mutation({
      query: ({pluginKey, data}) => {
        return {
          url: `/im-channel-profile/${pluginKey}`,
          method: "POST",
          body: data
        }
      }
    }),

    getProfileInfo: builder.query({
      query: ({pluginKey}) => ({
          url: `/im-channel-profile/${pluginKey}`,
          method: 'GET'
        }
      ),
      keepUnusedDataFor: 0
    }),

    getOfflineMessages: builder.query({
      query: ({pluginKey}) => ({
          url: `/im-channel-offline-response/${pluginKey}`,
          method: 'GET'
        }
      ),
    }),

    createOfflineMessage: builder.mutation({
      query: ({pluginKey, data}) => {
        return {
          url: `/im-channel-offline-response/${pluginKey}`,
          method: "POST",
          body: data
        }
      }
    }),

    updateOfflineMessage: builder.mutation({
      query: ({offlineResponseId, data}) => {
        return {
          url: `/im-channel-offline-response/${offlineResponseId}`,
          method: "PUT",
          body: data
        }
      }
    }),

    deleteOfflineMessage: builder.mutation({
      query: ({offlineResponseId}) => {
        return {
          url: `/im-channel-offline-response/${offlineResponseId}`,
          method: "DELETE",
        }
      }
    }),
  }),
})

export const {
  useGetSessionsQuery,
  useGetSessionMessagesQuery,
  useCreateMessageMutation,
  useReadChatMutation,
  useUploadAttachmentMutation,
  useGetCustomerSessionsQuery,
  useGetCustomerDetailsQuery,
  useCreateProfileMutation,
  useGetProfileInfoQuery,
  useGetOfflineMessagesQuery,
  useCreateOfflineMessageMutation,
  useUpdateOfflineMessageMutation,
  useDeleteOfflineMessageMutation,
  useUpdateCustomerDetailsMutation
} = imManagerChatApi
