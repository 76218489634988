import { Button, Spin } from 'antd';
import { getMemberByIdV2 } from 'apis/ums';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MemberBaseInfo from './base-info';
import MemberOrderList from './order-list';
import styles from './index.module.scss';
import MemberPointHistory from './point-history';
import MemberAddressList from './address-list';
import { Trans } from 'react-i18next';
import LOCALS from 'commons/locals';
import MemberMailHistory from './mail-history';
import RecyclingConsignmentHistory from './recycling-consignment-history';
import { UnwrapPromise } from 'types/base';

const MemberView = () => {
  const { id } = useParams<{ id: string }>();
  const [member, setMember] =
    useState<UnwrapPromise<ReturnType<typeof getMemberByIdV2>>>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) return;
    getMemberByIdV2(Number(id)).then((res) => {
      setMember(res);
    });
  }, [id]);

  const onBack = () => {
    navigate(-1);
  };

  if (!member) {
    return <Spin />;
  }

  return (
    <div>
      <MemberBaseInfo member={member} />
      <div className={styles.divider} />
      <MemberAddressList memberId={member.id} />
      <div className={styles.divider} />
      <MemberOrderList memberId={member.id} />
      <div className={styles.divider} />
      <MemberPointHistory memberId={member.id} />
      <div className={styles.divider} />
      <RecyclingConsignmentHistory memberId={member.id} />
      <div className={styles.divider} />
      <MemberMailHistory memberId={member.id} />
      <div className={styles.divider} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button onClick={onBack}>
          <Trans i18nKey={LOCALS.back} />
        </Button>
      </div>
    </div>
  );
};

export default MemberView;
