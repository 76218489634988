import { BASE_URL, ginzaxiaomaApiRequest } from 'apis';
import { PageQuery } from 'types/base';

export const s3UploadUrl = `${BASE_URL}/cms/s3/upload`;

export type CmsHelp = {
  id: number;
  categoryId: number;
  icon?: string;
  title: string;
  description?: string;
  recommendStatus: number;
  showStatus?: number;
  language: string;
  sort?: number;
  createTime: string;
  readCount?: number;
  content: string;
  modifyTime?: string;
  path?: string;
};

export type CmsHelpCategory = {
  id: number;
  name: string;
};

export const CmsApi = {
  getHelpList(
    dto: {
      keyword?: string;
      categoryIdList?: string;
      langList?: string;
    } & PageQuery
  ) {
    return ginzaxiaomaApiRequest.get<{
      list: CmsHelp[];
      total: number;
    }>('/admin/cms/help-list', {
      params: dto,
    });
  },

  helpPublish(id: CmsHelp['id']) {
    return ginzaxiaomaApiRequest.post(`/admin/cms/help/publish/${id}`);
  },

  helpUnPublish(id: CmsHelp['id']) {
    return ginzaxiaomaApiRequest.post(`/admin/cms/help/un-publish/${id}`);
  },

  getHelpDetail(id: CmsHelp['id']) {
    return ginzaxiaomaApiRequest.get<CmsHelp>(`/admin/cms/help/${id}`);
  },

  helpCreate(dto: Partial<CmsHelp>) {
    return ginzaxiaomaApiRequest.post<CmsHelp>(`/admin/cms/help/create`, dto);
  },

  helpUpdate(dto: Partial<CmsHelp>) {
    return ginzaxiaomaApiRequest.put<CmsHelp>(`/admin/cms/help/update`, dto);
  },

  helpDelete(id: CmsHelp['id']) {
    return ginzaxiaomaApiRequest.delete<CmsHelp>(`/admin/cms/help/${id}`);
  },

  getHelpCategory() {
    return ginzaxiaomaApiRequest.get<CmsHelpCategory[]>(
      '/admin/cms/help-category'
    );
  },
};
