import { CustomerSession } from './CustomerSession'
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SkeletonLoader } from './loader/SkeletonLoader';
import InfiniteScroll from 'react-infinite-scroll-component';

export const CustomerSessionList = () => {
  const authToken = useSelector(state => state.imManagerSettings.authToken)
  const baseUrl = useSelector(state => state.imManagerSettings.baseUrl)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [customerSessions, setCustomerSessions] = useState([])

  const fetchData = useCallback(async () => {
    try {
      const response = await fetch(`${baseUrl}/im-chat/manager/im-chat-list?pageNum=${page}&status=1&pageSize=20`, {
        headers: {
          'Authorization': `Bearer ${authToken}`
        }
      })

      const customerSessionData = await response.json()

      if (customerSessionData.list.length > 0) {
        setCustomerSessions(prevSessions => [...prevSessions, ...customerSessionData?.list])
        setPage(prevPage => prevPage + 1)
      }
      if(customerSessions.length === customerSessionData.total) {
        setHasMore(false)
      }
    } catch (error) {
      console.error('Failed to fetch data:', error)
    }
  }, [page, authToken])

  useEffect(() => {
    if(authToken){
      fetchData()
    }
  }, [authToken])

  return (
    <div
      id="scrollableDiv2"
      className="px-1 overflow-y-scroll !h-[90%] im-chat-scrollbar"
    >
      <InfiniteScroll
        dataLength={customerSessions.length}
        next={fetchData}
        hasMore={hasMore}
        loader={<SkeletonLoader/>}
        scrollableTarget="scrollableDiv2"
      >
        {customerSessions.map(
          session => {
            return (
              <CustomerSession
                key={session.id}
                session={session}
              />
            )
          })}
      </InfiniteScroll>
    </div>
  )
}