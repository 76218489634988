import { Divider, Typography } from 'antd';
import {SessionList} from "./SessionList.jsx"
import {SessionInfo} from "./SessionInfo.jsx"
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next"

const { Title } = Typography

export const Sessions = () => {
  const {resetSessionList} = useSelector(state => state.imManagerSettings)
  const {t} = useTranslation()
  return (
    <div className="flex justify-center flex-col h-[100%]">
      <Title className="!h-[8%] !p-2 !flex !items-center !m-0 !cursor-default !text-[20px]">
        {t('sessions')}
      </Title>
      <SessionInfo/>
      <Divider className="!mt-4 !mb-2 !text-black"/>
      <SessionList key={resetSessionList}/>
    </div>
  )
}
