import {
  Button,
  Cascader,
  Form,
  Input,
  Select,
  Space,
  Switch,
  Table,
  DatePicker,
  message,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { getProductList, updateProductPublishStatus } from 'apis/pms';
import useIsMobile from 'commons/hooks/useIsMobile';
import usePagination from 'commons/hooks/usePagination';
import LOCALS from 'commons/locals';
import {
  PRODUCT_SOURCE_TYPE_OPTION_LIST,
  PROMOTION_TYPE_MAP,
  PUBLISH_STATUS_OPTION_LIST,
  STOCK_PLACE_OPTION_LIST,
  findLabelByValue,
  CURRENCY_MAP,
  CURRENCY_MAP_TO_SHOP,
} from 'commons/options';
import dayjs, { Dayjs } from 'dayjs';
import i18n from 'i18n';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { selectGlobalInfo } from 'store/slices/globalInfoSlice';
import { PageQuery } from 'types/base';
import { PmsProduct, PriceTagInfo, ProductUpdateInfo } from 'types/pms';
import formatTime from 'utils/formatTime';
import setQueryParameters from 'utils/setQueryParameters';
import ImageSliceShow from 'components/image-slice-show';
import { useTodayRateMap } from 'apis/home';
import classNames from 'classnames';
import findCascaderOptionById from 'utils/findCascaderOptionById';
import useResource from 'commons/hooks/useResource';

const { RangePicker } = DatePicker;

type SearchFormData = {
  productCategoryIds?: number[];
  keyword?: string;
  stockPlace?: string;
  isFilterPromotion?: boolean;
  publishStatus?: number;
  supportCrypto: boolean;
  createTime: Dayjs[];
};

type ProductType = PmsProduct & ProductUpdateInfo;

const ProductList = () => {
  const isMobile = useIsMobile();
  const [form] = Form.useForm<SearchFormData>();
  const {
    productCategoryCascaderOptions,
    colorSelectOptions,
    materialCascaderOptionsMap,
    hardwareSelectOptions,
    rankSelectOptions,
    stampSelectOptions,
  } = useAppSelector(selectGlobalInfo);
  const {
    loading,
    setLoading,
    pageNum,
    setPageNum,
    pageSize,
    setPageSize,
    total,
    setTotal,
    dataSource,
    setDataSource,
  } = usePagination<PmsProduct>();
  const [selectedRows, setSelectedRows] = useState<PmsProduct[]>([]);
  const viewPrint = useResource('product-list-view-print');

  const todayRateMap = useTodayRateMap();

  const getDataSource = useCallback(
    async ({ pageNum, pageSize }: PageQuery) => {
      const {
        productCategoryIds,
        keyword,
        publishStatus,
        stockPlace,
        isFilterPromotion,
        supportCrypto,
        createTime,
      } = form.getFieldsValue();

      let [start, end] = ['', ''];
      if (createTime) {
        [start, end] = [
          dayjs(createTime[0]).startOf('day').format(),
          dayjs(createTime[1]).endOf('day').format(),
        ];
      }

      setQueryParameters({
        pageNum,
        pageSize,
        keyword,
        stockPlace,
        isFilterPromotion,
        publishStatus,
        productCategoryIds,
        supportCrypto,
        start,
        end,
      });

      try {
        setLoading(true);

        const {
          data: { list, total },
        } = await getProductList({
          pageNum,
          pageSize,
          keyword,
          stockPlace,
          isFilterPromotion,
          publishStatus,
          productCategoryId: productCategoryIds
            ? productCategoryIds[productCategoryIds.length - 1]
            : undefined,
          // 这个接口默认返回的是转换为日元的价格，加上这个参数并且设置为 0，可以返回商品本来的价格和汇率
          transformPriceToJpyFlag: 0,
          supportCrypto,
          start,
          end,
        });

        setDataSource(list);
        setTotal(total);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    },
    [form, setDataSource, setLoading, setTotal]
  );

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);

    if (parsed.isFilterPromotion && parsed.isFilterPromotion === 'true') {
      // @ts-ignore
      parsed.isFilterPromotion = true;
    } else {
      // @ts-ignore
      parsed.isFilterPromotion = false;
    }

    // @ts-ignore
    parsed.publishStatus = Number(parsed.publishStatus) || '';
    // @ts-ignore
    parsed.stockPlace = parsed.stockPlace || '';
    // @ts-ignore
    if (parsed.start && parsed.end) {
      // @ts-ignore
      parsed.createTime = [dayjs(parsed.start), dayjs(parsed.end)];
    }

    if (parsed.productCategoryIds) {
      parsed.productCategoryIds = parsed.productCategoryIds
        // @ts-ignore
        .split(',')
        // @ts-ignore
        .map((i) => +i);
    }

    form.setFieldsValue(parsed);

    const pageNum = parsed.pageNum ? +parsed.pageNum : 1;
    const pageSize = parsed.pageSize ? +parsed.pageSize : 10;
    setPageNum(pageNum);
    setPageSize(pageSize);

    getDataSource({ pageNum, pageSize });
  }, [form, getDataSource, setPageNum, setPageSize]);

  const onFinish = async () => {
    setPageNum(1);
    getDataSource({ pageNum: 1, pageSize });
  };

  const onReset = () => {
    form.resetFields();
    onFinish();
  };

  const onClickAdd = () => {
    // 为改变url 触发 beforeunload
    window.location.href = '/pms/product-add';
  };

  const onEdit = useCallback((id: PmsProduct['id']) => {
    // 为改变url 触发 beforeunload
    window.location.href = `/pms/product-edit/${id}`;
  }, []);

  const handlePublishStatusChange = useCallback(
    async (checked: boolean, id: PmsProduct['id']) => {
      const publishStatus = checked ? 1 : 0;
      await updateProductPublishStatus({
        ids: id,
        publishStatus,
      });
      getDataSource({ pageNum, pageSize });
    },
    [getDataSource, pageNum, pageSize]
  );

  /** 跳转打印 */
  const handleForwardPrint = useCallback(
    (data: ProductType[]) => {
      if (data.length > 18) {
        message.warning(i18n.t('supports_up_to_18_items'));
        return;
      }
      const newData: PriceTagInfo[] = [];
      data.forEach((d) => {
        const {
          id,
          name,
          productCategoryId,
          productSn,
          price,
          sourceType,
          attrColor,
          attrMaterial,
          attrHardware,
          rank,
          attrStamp,
          currency,
          createdTime,
        } = d;
        // color
        const color: string[] = [];
        attrColor &&
          attrColor.split(',')?.forEach((d: string) => {
            const t = colorSelectOptions.find((dd) => dd.value === d);
            if (t) color.push(t.label);
          });
        // material
        const material: string[] = [];
        const target = findCascaderOptionById(
          productCategoryId,
          productCategoryCascaderOptions
        );
        const productCategoryIds =
          target?.treeIds?.split(',')?.map(Number) || [];
        const mateList =
          materialCascaderOptionsMap[productCategoryIds[0]] || [];
        attrMaterial &&
          attrMaterial.split(',')?.forEach((d: string) => {
            const t = findCascaderOptionById(d, mateList);
            if (t) material.push(t.label);
          });
        //
        const rankStr = rankSelectOptions.find((d) => d.value === rank)?.label;
        //
        const stampStr =
          stampSelectOptions.find((d) => d.value === attrStamp)?.label || '';
        //
        const hardware: string[] = [];
        attrHardware &&
          attrHardware.split(',')?.forEach((value) => {
            const t = hardwareSelectOptions.find((dd) => dd.value === value);
            if (t) hardware.push(t.label);
          });
        newData.push({
          id: `${id}`,
          name,
          productCategoryId: `${productCategoryId}`,
          productSn,
          price,
          sourceType,
          color: color.toString(),
          material: material.toString(),
          hardware: hardware.toString(),
          rank: rankStr,
          shopId: CURRENCY_MAP_TO_SHOP[currency as 'JPY' | 'HKD' | 'SGD'],
          stamp: stampStr,
          createTime: createdTime,
        });
      });

      // 填充数据
      const arrayFill = Array.from({ length: 18 }, (_, i) => {
        if (newData[i]) {
          return { ...newData[i] };
        } else {
          return { id: i };
        }
      });

      localStorage.setItem('printData', JSON.stringify(arrayFill));
      window.open('/prints/cash-register');
    },
    [
      productCategoryCascaderOptions,
      materialCascaderOptionsMap,
      rankSelectOptions,
      stampSelectOptions,
      colorSelectOptions,
      hardwareSelectOptions,
    ]
  );

  const columns: ColumnsType<PmsProduct> = useMemo(() => {
    return [
      {
        title: <Trans i18nKey={LOCALS.product_sn}></Trans>,
        dataIndex: 'productSn',
        width: 150,
        key: 'productSn',
        render: (productSn: string, { id, description }: PmsProduct) => {
          return (
            <>
              <div>{id}</div>
              <div
                className="text-[#69b1ff] cursor-pointer"
                onClick={() => {
                  const host = window.location.host;
                  if (host === 'admin-shop.ginzaxiaoma.com') {
                    window.open(`//ginzaxiaoma.com/product/${id}`);
                  } else {
                    window.open(`//test-shop.ginzaxiaoma.com/product/${id}`);
                  }
                }}
              >
                {description?.trim()
                  ? `${productSn} (${description.trim()})`
                  : productSn}
              </div>
            </>
          );
        },
      },
      {
        dataIndex: 'albumPics',
        key: 'albumPics',
        width: 100,
        title: <Trans i18nKey={LOCALS.product_pictures} />,
        align: 'center',
        render: (albumPics: string) => {
          if (!albumPics) return <span>-</span>;
          let picList: string[] = albumPics.split(',');
          return <ImageSliceShow imgList={picList} endSliceNumber={1} />;
        },
      },
      {
        dataIndex: 'name',
        key: 'name',
        width: 200,
        title: <Trans i18nKey={LOCALS.product_name} />,
        render: (name: string, product: PmsProduct) => {
          return (
            <div>
              <p>{name}</p>
              {product.brandName && (
                <p>
                  <Trans i18nKey={LOCALS.brand} />：{product.brandName}
                </p>
              )}
            </div>
          );
        },
      },
      {
        key: 'publishStatus',
        width: 100,
        title: <Trans i18nKey={LOCALS.publish_status} />,
        render: (_: any, { publishStatus, id }: PmsProduct) => {
          return (
            <div className="mb-2 text-center">
              <Switch
                checked={publishStatus === 1}
                onChange={(checked) => {
                  handlePublishStatusChange(checked, id);
                }}
              />
            </div>
          );
        },
      },
      {
        key: 'sourceType',
        dataIndex: 'sourceType',
        width: 100,
        title: <Trans i18nKey={LOCALS.product_source} />,
        render: (sourceType: PmsProduct['sourceType']) => {
          return findLabelByValue(sourceType, PRODUCT_SOURCE_TYPE_OPTION_LIST);
        },
      },
      {
        dataIndex: 'stockPlace',
        key: 'stockPlace',
        width: 100,
        title: <Trans i18nKey={LOCALS.stock_place} />,
        render: (stockPlace: PmsProduct['stockPlace']) => {
          return findLabelByValue(stockPlace, STOCK_PLACE_OPTION_LIST);
        },
      },
      {
        dataIndex: 'price',
        key: 'price',
        width: 140,
        title: <Trans i18nKey={LOCALS.price} />,
        render: (price: number, product: PmsProduct) => {
          const { promotionType } = product;

          if (promotionType === PROMOTION_TYPE_MAP.SPECIAL) {
            return (
              <div>
                <p
                  style={{
                    textDecoration: 'line-through',
                  }}
                >
                  {product.currency} {product.originalPrice.toLocaleString()}
                </p>
                <p
                  style={{
                    color: 'var(--color-danger)',
                  }}
                >
                  {product.currency} {product.price?.toLocaleString() || '-'}
                </p>
              </div>
            );
          }

          // 实际支付金额，按照当前汇率所转换的日元价格
          const rate = todayRateMap[product.currency];
          if (!rate) return '-';
          const JPY = Math.floor(product.price / rate) || '-';

          return (
            <>
              <div>
                {product.currency} {product.price?.toLocaleString() || '-'}
              </div>
              {product.currency !== CURRENCY_MAP.JPY && (
                <div>JPY {JPY.toLocaleString() || '-'}</div>
              )}
            </>
          );
        },
      },
      {
        dataIndex: 'createdTime',
        key: 'createdTime',
        width: 180,
        title: <Trans i18nKey={LOCALS.created_time} />,
        render: (createdTime: PmsProduct['createdTime']) => {
          return formatTime(createdTime);
        },
      },
      {
        key: 'options',
        title: <Trans i18nKey={LOCALS.options} />,
        width: 150,
        fixed: 'right',
        render: ({ id }: PmsProduct, record) => {
          return (
            <div>
              {viewPrint && (
                <Button
                  type="link"
                  onClick={() => handleForwardPrint([record as ProductType])}
                >
                  <Trans i18nKey={LOCALS.print} />
                </Button>
              )}
              <Button
                type="link"
                onClick={() => {
                  onEdit(id);
                }}
              >
                <Trans i18nKey={LOCALS.edit} />
              </Button>
            </div>
          );
        },
      },
    ];
  }, [
    handleForwardPrint,
    handlePublishStatusChange,
    onEdit,
    todayRateMap,
    viewPrint,
  ]);

  return (
    <div>
      <Form
        form={form}
        layout={isMobile ? 'vertical' : 'inline'}
        onFinish={onFinish}
      >
        <Form.Item
          name="productCategoryIds"
          label={<Trans i18nKey={LOCALS.product_category}></Trans>}
        >
          <Cascader
            changeOnSelect
            placeholder={i18n.t(LOCALS.please_select) || ''}
            style={{ minWidth: 120 }}
            options={productCategoryCascaderOptions}
          />
        </Form.Item>
        <Form.Item
          name="keyword"
          label={<Trans i18nKey={LOCALS.keyword}></Trans>}
        >
          <Input.TextArea placeholder={i18n.t(LOCALS.please_enter) || ''} />
        </Form.Item>

        <Form.Item
          name="publishStatus"
          label={<Trans i18nKey={LOCALS.publish_status}></Trans>}
          initialValue={''}
        >
          <Select
            style={{ minWidth: 120 }}
            placeholder={i18n.t(LOCALS.please_select) || ''}
          >
            <Select.Option value="">{i18n.t('all')}</Select.Option>
            {PUBLISH_STATUS_OPTION_LIST.map((d) => (
              <Select.Option key={d.value} value={d.value}>
                {d.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="stockPlace"
          label={<Trans i18nKey={LOCALS.stock_place} />}
          initialValue={''}
        >
          <Select
            style={{ minWidth: 120 }}
            placeholder={i18n.t(LOCALS.please_select) || ''}
          >
            <Select.Option value="">{i18n.t('all')}</Select.Option>
            {STOCK_PLACE_OPTION_LIST.map((d) => (
              <Select.Option key={d.value} value={d.value}>
                {d.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="createTime"
          label={<Trans i18nKey={LOCALS.created_time} />}
        >
          <RangePicker
            className={classNames('w-full', {
              isMobile: 'w-[220px]',
            })}
          />
        </Form.Item>

        <Form.Item
          name="isFilterPromotion"
          valuePropName="checked"
          label={<Trans i18nKey={LOCALS.on_sale} />}
        >
          <Switch></Switch>
        </Form.Item>
        {/* <Form.Item
          name="supportCrypto"
          valuePropName="checked"
          label={<Trans i18nKey={LOCALS.support_cryptocurrency_payments} />}
        >
          <Switch></Switch>
        </Form.Item> */}

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              <Trans i18nKey={LOCALS.search} />
            </Button>
            <Button htmlType="button" onClick={onReset}>
              <Trans i18nKey={LOCALS.reset} />
            </Button>
            <Button onClick={onClickAdd}>
              <Trans i18nKey={LOCALS.add} />
            </Button>
            {viewPrint && (
              <Button
                disabled={selectedRows.length === 0}
                onClick={() =>
                  handleForwardPrint(selectedRows as ProductType[])
                }
              >
                <Trans i18nKey={LOCALS.batch_print} />
              </Button>
            )}
          </Space>
        </Form.Item>
      </Form>

      <Table
        bordered
        tableLayout="fixed"
        rowSelection={{
          selectedRowKeys: selectedRows.map((d) => d.id) as React.Key[],
          onChange: (_, selectedRows) => setSelectedRows(selectedRows),
        }}
        pagination={{
          total,
          pageSize,
          current: pageNum,
          showTotal: (total) => `${i18n.t('total')} ${total} ${i18n.t('item')}`,
          onChange: (page, pageSize) => {
            setPageNum(page);
            setPageSize(pageSize);
            getDataSource({ pageNum: page, pageSize });
          },
        }}
        loading={loading}
        rowKey={'id'}
        style={{
          marginTop: 12,
        }}
        scroll={{ x: 'max-content' }}
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );
};

export default ProductList;
