import { Modal, Checkbox, InputNumber, Input } from 'antd';
import classNames from 'classnames';
import { usePayloadContext } from 'pages/oms/checkout-counter/utils/payload-context';
import { ActionType } from 'pages/oms/checkout-counter/utils/payload-reducer';
import { thousands } from 'utils/tools';
import i18n from '../../../i18n';
import LOCALS from '../../../commons/locals';
import { StoreConfirmOrder } from 'types/oms';

type Props = {
  open: boolean;
  onCancel: () => void;
  currency: string;
  totalPrice: number;
  orderResult?: StoreConfirmOrder;
};

const itemsStyle = 'p-4 flex justify-between bg-[#F9F9F9]';

const ChangePriceModal = ({
  open,
  onCancel,
  currency,
  totalPrice,
  orderResult,
}: Props) => {
  const { dispatch, state } = usePayloadContext();
  const {
    isPointsGiven,
    useIntegration,
    memberId,
    promotionAmount,
    totalIntegration,
    couponCode,
  } = state;

  return (
    <Modal
      onOk={onCancel}
      onCancel={onCancel}
      open={open}
      title={i18n.t(LOCALS.price_adjustment)}
      width={780}
      destroyOnClose
    >
      <div className="grid grid-cols-2 gap-6 mb-5">
        <div className={classNames(itemsStyle)}>
          <span>{i18n.t(LOCALS.order_amount)}</span>
          <span>
            {currency} {thousands(totalPrice)}
          </span>
        </div>
        <div className={classNames(itemsStyle)}>
          <span>{i18n.t(LOCALS.amount_to_be_paid)}</span>
          <span>
            {currency} {thousands(totalPrice)}
          </span>
        </div>
        <div className={classNames(itemsStyle)}>
          <span>{i18n.t(LOCALS.points_deduction)}</span>
          <div>
            <span className="mr-1">JPY</span>
            <InputNumber
              className="w-[150px]"
              placeholder=""
              size="small"
              value={useIntegration}
              max={totalIntegration || 0}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              onChange={(e) => {
                if (e !== null) {
                  dispatch({
                    type: ActionType.UPDATE_BATCH,
                    payload: {
                      useIntegration: e,
                    },
                  });
                }
              }}
            />
          </div>
        </div>
        <div className={classNames(itemsStyle)}>
          <span>{i18n.t(LOCALS.discount_amounts)} </span>
          <span>
            <span className="mr-1">{currency}</span>
            <InputNumber
              min={0}
              max={totalPrice}
              value={promotionAmount}
              className="w-[150px]"
              placeholder=""
              size="small"
              onChange={(e) => {
                dispatch({
                  type: ActionType.UPDATE_BATCH,
                  payload: {
                    promotionAmount: e || 0,
                  },
                });
              }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              // parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
            />
          </span>
        </div>
      </div>
      <Input
        className="mb-4"
        value={couponCode}
        addonBefore={<div className="w-[160px]">{i18n.t('coupon')}</div>}
        onChange={(e) => {
          dispatch({
            type: ActionType.UPDATE_BATCH,
            payload: {
              couponCode: e.target.value.trim(),
            },
          });
        }}
      />
      {orderResult?.smsCouponHistory && (
        <div className="text-sm text-[#999] mb-4">
          {orderResult?.smsCouponHistory?.smsCoupon?.name}：
          {orderResult?.smsCouponHistory?.couponCode}
          <span className="ml-4">
            {i18n.t('discount_amounts')}：{orderResult?.omsOrder?.couponAmount}
            （JPY）
          </span>
        </div>
      )}
      <div className="flex items-center h-[32px]">
        <div className="mr-2">
          <Checkbox
            onChange={(e) => {
              dispatch({
                type: ActionType.UPDATE_BATCH,
                payload: {
                  isPointsGiven: e.target.checked,
                },
              });
            }}
            checked={isPointsGiven}
            disabled={!memberId}
          />
        </div>

        <div className="mr-2">{i18n.t(LOCALS.points_earned)}</div>
      </div>
    </Modal>
  );
};

export default ChangePriceModal;
