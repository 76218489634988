import { Button, Form, Input, Modal, Select, message } from 'antd';
import { modifyOmsRecycleOrderDetails } from 'apis/oms';
import { CURRENCY_OPTION_LIST } from 'commons/options';
import { useState } from 'react';
import i18n from '../../../i18n';
import LOCALS from '../../../commons/locals';

const ModifyValuation = ({
  id,
  currency,
  onSuccess,
}: {
  id?: string;
  currency?: string;
  onSuccess: () => void;
}) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Modal
        open={open}
        title={i18n.t(LOCALS.update_valuation_information)}
        onCancel={() => {
          setOpen(false);
        }}
        onOk={async () => {
          const data = form.getFieldsValue();
          await modifyOmsRecycleOrderDetails(data);
          message.success(i18n.t(LOCALS.successful_operation));
          setOpen(false);
          onSuccess();
        }}
      >
        <Form
          form={form}
          initialValues={{
            currency,
            id,
          }}
        >
          <Form.Item label={i18n.t(LOCALS.quotation_currency)} name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item label={i18n.t(LOCALS.quotation_currency)} name="currency">
            <Select options={CURRENCY_OPTION_LIST}></Select>
          </Form.Item>
        </Form>
      </Modal>

      <Button
        type="primary"
        onClick={() => {
          setOpen(true);
        }}
      >
        {i18n.t(LOCALS.update_valuation_information)}
      </Button>
    </div>
  );
};

export default ModifyValuation;
