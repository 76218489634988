import { Button, Modal } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { getMailTemplateList } from 'apis/ums';
import usePagination from 'commons/hooks/usePagination';
import LOCALS from 'commons/locals';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { PageQuery } from 'types/base';
import { UmsMemberMailTemplate } from 'types/ums';

const MemberMailSend = () => {
  const [mailTemplate, setMailTemplate] =
    useState<UmsMemberMailTemplate | null>(null);

  const {
    loading,
    setLoading,
    pageNum,
    setPageNum,
    pageSize,
    setPageSize,
    total,
    setTotal,
    dataSource,
    setDataSource,
  } = usePagination<UmsMemberMailTemplate>();

  const getDataSource = useCallback(
    async ({ pageNum, pageSize }: PageQuery) => {
      try {
        setLoading(true);
        const {
          data: { list, total },
        } = await getMailTemplateList({
          pageNum,
          pageSize,
        });
        setDataSource(list);
        setTotal(total);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    },
    [setDataSource, setLoading, setTotal]
  );

  useEffect(() => {
    getDataSource({ pageNum: 1, pageSize: 10 });
  }, [getDataSource]);

  const columns: ColumnsType<UmsMemberMailTemplate> = useMemo(() => {
    return [
      {
        title: 'ID',
        key: 'id',
        dataIndex: 'id',
        width: 100,
      },
      {
        title: '名称',
        key: 'description',
        dataIndex: 'description',
        width: 200,
      },
      {
        title: '面额',
        key: 'subject',
        dataIndex: 'subject',
      },
      {
        title: '使用门槛',
        key: 'subject',
        dataIndex: 'subject',
      },
      {
        title: 'Code',
        key: 'subject',
        dataIndex: 'subject',
      },
      {
        title: '有效期',
        key: 'subject',
        dataIndex: 'subject',
      },
      {
        title: '使用状态',
        key: 'subject',
        dataIndex: 'subject',
      },
      {
        title: '用户',
        key: 'subject',
        dataIndex: 'subject',
      },
      {
        title: '创建者',
        key: 'subject',
        dataIndex: 'subject',
      },
      {
        title: '创建时间',
        key: 'subject',
        dataIndex: 'subject',
      },
    ];
  }, []);

  return (
    <div>
      <Table
        bordered
        tableLayout="fixed"
        pagination={{
          total,
          pageSize,
          current: pageNum,
          onChange: (page, pageSize) => {
            setPageNum(page);
            setPageSize(pageSize);
            getDataSource({ pageNum: page, pageSize });
          },
        }}
        loading={loading}
        rowKey={'id'}
        style={{
          marginTop: 12,
        }}
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );
};

export default MemberMailSend;
