import {useState, useRef, useEffect} from 'react'
import { useGetSessionMessagesQuery, useReadChatMutation} from "../../store/im-chat-stores/imManagerChatApi.js"
import {useDispatch, useSelector} from "react-redux"
import {MessageLoader} from "./loader/MessageLoader.jsx"
import {MessageBody} from "./MessageBody.jsx"
import {MessageForm} from "./MessageForm.jsx"
import PropTypes from "prop-types"
import { insertMessage } from '../../store/im-chat-stores/imManagerSettingsSlice.js';

export const MessageList = ({session}) => {
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef(null)
  const chatContainerRef = useRef(null)
  const dispatch = useDispatch()
  const customer = useSelector(state => state.imManagerSettings.selectedCustomer)
  const messageList = useSelector(state => state.imManagerSettings.messageList)
  const [readChat] = useReadChatMutation()

  const {data : userMessages, isFetching } = useGetSessionMessagesQuery({
    session: session,
  }, {
    skip: !session?.id
  })

  useEffect(() => {
    if (userMessages?.imMessageList) {
      dispatch(insertMessage(userMessages.imMessageList))
    }
  }, [userMessages])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto'
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`
    }
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
    }
  }, [inputValue, messageList])

  useEffect(() => {
    readChat({session: session})
  }, [messageList])

  return (
    <div className="flex flex-col justify-between bg-white h-[100%]">
      <div
        ref={chatContainerRef}
        className="static pt-2 flex flex-col w-full bg-white rounded-lg shadow-lg px-4 h-[90%] overflow-auto scroll-smooth space-y-2"
      >
        {isFetching ? (
          <MessageLoader/>
        ) : (
          messageList.map((message) => (
            <MessageBody
              key={message.id}
              customer={customer}
              message={message}
            />
          ))
        )}
      </div>
      <div className="pt-3 sticky bottom-0 w-full h-auto bg-white">
        <MessageForm inputRef={inputRef} setInputValue={setInputValue} inputValue={inputValue} />
      </div>
    </div>
  )
}

MessageList.propTypes = {
  session: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
}
